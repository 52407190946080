/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Gearbox } from './pages/Gearbox';
import { NotFoundPage } from './pages/NotFoundPage';
import { GlobalStyle } from './styles/global-styles';
import { useState } from 'react';
// import { Box, IconButton } from '@mui/material';
// import { DarkMode, LightMode } from '@mui/icons-material';
import { Gig } from 'pages/Gig';
import { ChibiChubi } from 'pages/ChibiChubi';
import { WordBee } from 'pages/WordBee';
import { HomePage } from 'pages/HomePage';
import About from 'pages/About';
import { BlogView } from 'pages/Gig/BlogView';
import QuestionAndAnswer from 'pages/HomePage/QuestionAndAnswer';
import GospelPathway from 'pages/HomePage/GospelPathway';

export function App() {
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('dark');

  // const toggleTheme = () => {
  //   setThemeMode((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  // };

  return (
    // TODO: Update basename
    // <BrowserRouter basename="/gearbox">
    <BrowserRouter>
      {/* TODO: Update Page Title */}
      <Helmet titleTemplate="%s - Arkware" defaultTitle="Arkware">
        <meta name="description" content="Arkware" />
      </Helmet>

      <GlobalStyle theme={{ themeMode }} themeMode={themeMode} />

      {/* Toggle theme */}
      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={toggleTheme} color="inherit">
          {themeMode === 'light' ? (
            <DarkMode sx={{ color: '#436aba' }} />
          ) : (
            <LightMode sx={{ color: '#aaa57d' }} />
          )}
        </IconButton>
      </Box> */}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/select/:choice" element={<QuestionAndAnswer />} />
        <Route path="/HomoDeus" element={<GospelPathway />} />
        <Route path="/about" element={<About />} />
        <Route path="/gearbox7" element={<Gearbox />} />
        <Route path="/gig" element={<Gig />} />
        <Route path="/gig/blog/:id" element={<BlogView />} />
        <Route path="/chibichubi" element={<ChibiChubi />} />
        <Route path="/wordbee" element={<WordBee />} />
        {/* <Route path="*" element={<NotFoundPage />} /> */}
        {/* <Route path="*" element={<QuestionAndAnswer />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
