import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { acceptOption } from './acceptOption';
import { rejectOption } from './rejectOption';
import { QAItemType } from './QAItemType';
import { MatrixRainingLetters } from './MatrixRainingLetters';
import AnimatedButton from './AnimatedButton';

const QuestionAndAnswer: React.FC = () => {
  const { choice } = useParams<{ choice: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  //   const qaList = choice === 'accept' ? acceptOption : rejectOption;

  // Ensure choice is either 'accept' or 'reject', otherwise default to 'none'
  const validatedChoice =
    choice === 'accept' || choice === 'reject' ? choice : 'none';
  const qaList: QAItemType[] =
    validatedChoice === 'accept'
      ? acceptOption
      : validatedChoice === 'reject'
      ? rejectOption
      : [
          {
            question: '',
            answer: '',
          },
          {
            question: 'Error 404 Page Not Found.',
            answer:
              "Looks like you're lost... but don't worry, you're in good company! The Son of Man came to seek and save the lost (Luke 19:10). While this page may not exist, Jesus does and He's the best destination. Try heading back home!",
          },
        ];

  const [displayedText, setDisplayedText] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isComplete, setIsComplete] = useState(false);

  const textContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentIndex < qaList.length) {
      const fullText = `${qaList[currentIndex].question}\n${qaList[currentIndex].answer}\n\n`;
      if (charIndex < fullText.length) {
        const timeout = setTimeout(() => {
          setDisplayedText((prev) => prev + fullText[charIndex]);
          setCharIndex((prev) => prev + 1);

          // scroll to the bottom when text updates
          if (textContainerRef.current) {
            requestAnimationFrame(() => {
              // ensures the scrolling happens after the next repaint,
              // preventing race conditions where content updates but
              // scrolling occurs before layout recalculations.
              textContainerRef.current?.scrollTo({
                top: textContainerRef.current.scrollHeight,
                behavior: 'smooth',
              });
            });
          }
        }, 60); // typing speed
        return () => clearTimeout(timeout);
      } else {
        setTimeout(() => {
          setCurrentIndex((prev) => prev + 1);
          setCharIndex(0);
        }, 1000); // delay before next question
      }
    } else {
      setIsComplete(true);
    }
  }, [charIndex, currentIndex, qaList]);

  return (
    <>
      <MatrixRainingLetters />
      <Box
        ref={textContainerRef}
        sx={{
          color: '#00FF41',
          fontFamily: '"Space Mono", monospace',
          // fontSize: '1.2rem',
          padding: '20px',
          height: '90vh', // full viewport height
          boxSizing: 'border-box', // ensures padding doesn't cause overflow
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          overflowY: 'auto',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          scrollbarWidth: 'thin', // works in Firefox
          '&::-webkit-scrollbar': { width: '6px' },
          '&::-webkit-scrollbar-thumb': {
            background: '#00FF41',
            borderRadius: '3px',
          },
          '@media (max-width: 600px)': {
            fontSize: '1rem',
            padding: '10px',
          },
          zIndex: 10,
          letterSpacing: '0.01em',
        }}
      >
        <Typography
          variant={isMobile ? 'body2' : 'h6'}
          component="pre"
          sx={{
            flexGrow: 1, // ensures content fills available space
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            fontFamily: '"Space Mono", monospace',
            width: '100%',
            letterSpacing: '0.01em',
            zIndex: 10,
          }}
        >
          {displayedText}
          <span className="blinking-cursor">
            ▊
            <br />
          </span>
        </Typography>

        <style>
          {`
          .blinking-cursor {
            animation: blink 1s step-end infinite;
            fontVariantLigatures: 'none';
          }
          @keyframes blink { 
            50% { opacity: 0; } 
          }
        `}
        </style>
        {validatedChoice === 'none' && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
              mt: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AnimatedButton buttonColor={'green'} text={'home'} delay={'0s'} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default QuestionAndAnswer;
