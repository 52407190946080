import React, { useRef, useEffect } from 'react';
import '@fontsource/space-mono';

export const MatrixRainingLetters: React.FC<{ color?: string }> = ({
  color = '#00FF41',
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    const verses = [
      ' Repent ye: for the kingdom of heaven is at hand',
      ' That whosoever believeth in him should not perish',
      ' For God so loved the world, that he gave his only begotten Son',
      ' that whosoever believeth in him should not perish',
      ' For God sent not his Son into the world to condemn the world',
      ' but that the world through him might be saved',
      ' He that believeth on him is not condemned',
      ' but he that believeth not is condemned already',
      ' believed in the name of the only begotten Son of God',
      ' without shedding of blood is no remission',
      ' Repent ye: for the kingdom of heaven is at hand',
      ' For by grace are ye saved through faith',
      ' it is the gift of God: Not of works',
      ' Not by works of righteousness which we have done',
      ' And I give unto them eternal life; and they shall never perish',
      ' but according to his mercy he saved us',
      ' a man is justified by faith without the deeds of the law.',
      ' all our righteousnesses are as filthy rags',
      ' This is the work of God, that ye believe on him whom he hath sent.',
      ' even your sanctification, that ye should abstain from fornication',
    ];

    const updateFontSize = () => {
      const baseFontSize = 20;
      return Math.max(baseFontSize * (window.innerWidth / 1440), 15); // minimum 15px for smaller screens
    };

    let fontSize = updateFontSize();
    let columns = Math.floor(canvas.width / fontSize);
    let rainDrops = new Array(columns).fill(0);

    const render = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = color;
      ctx.font = `${fontSize}px "Space Mono", monospace`;

      rainDrops.forEach((y, i) => {
        const verse = verses[i % verses.length];
        const charIndex = Math.floor(y % verse.length);
        const text = verse.charAt(charIndex);

        ctx.fillText(text, i * fontSize, y * fontSize);

        if (y * fontSize > canvas.height && Math.random() > 0.975) {
          rainDrops[i] = 0;
        }
        rainDrops[i]++;
      });
    };

    const interval = setInterval(render, 100);

    // recalculate font size and columns on resize
    const handleResize = () => {
      setCanvasSize();
      fontSize = updateFontSize();
      columns = Math.floor(canvas.width / fontSize);
      rainDrops = new Array(columns).fill(0);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', setCanvasSize);
    };
  }, [color]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        pointerEvents: 'none',
        backgroundColor: 'transparent',
        opacity: 0.25,
        fontFamily: 'Space Mono, sans-serif',
      }}
    />
  );
};
