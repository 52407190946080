/**
 *
 * BlogView
 *
 */

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  CssBaseline,
  Button,
  CardMedia,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactMarkdown from 'react-markdown';
import '@fontsource/eagle-lake';
import '@fontsource/playfair-display';

// Mock data generator for blog posts (reuse the same data generation as in BlogList)
const generateBlogPosts = () => {
  const categories = ['Salvation', 'Trinity', 'Rapture', 'Sanctification'];

  const images = [
    'https://picsum.photos/id/1/800/600',
    'https://picsum.photos/id/2/800/600',
    'https://picsum.photos/id/3/800/600',
    'https://picsum.photos/id/4/800/600',
    'https://picsum.photos/id/5/800/600',
  ];
  return Array.from({ length: 20 }, (_, index) => ({
    id: index + 1,
    title: `Blog Post ${index + 1}`,
    description: `This is the description for blog post ${
      index + 1
    }. It provides a brief overview of the blog's content and theme.`,
    content: `
## Introduction

This blog post covers the following topics:

- Overview of the subject
- Key takeaways
- Practical examples

### Subheading Example

Here is a **bold statement**, some _italicized text_, & even a [link](https://example.com).

#### Code Block Example:

\`\`\`javascript
console.log('Hello, World!');
\`\`\`

> A quote block to emphasize an important point.

Enjoy reading!

---

Thank you for visiting!

    `,
    image: images[index % images.length],
    category: categories[index % categories.length],
    date: `July ${index + 1}, 2023`,
    authors: `Author ${index + 1}`,
  }));
};

const blogPosts = generateBlogPosts();

export const BlogView = () => {
  const { id } = useParams<{ id: string }>(); // Get blog ID from URL
  const navigate = useNavigate();

  // Find the blog post by ID
  const blogPost = blogPosts.find((post) => post.id === parseInt(id || '0'));

  if (!blogPost) {
    return (
      <Container sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h4" color="error">
          Blog post not found
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Container>
    );
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="relative" sx={{ backgroundColor: '#2e7d32' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            sx={{ fontFamily: 'Eagle Lake, cursive' }}
          >
            {blogPost.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Container
          sx={{
            py: 8,
            backgroundColor: '#d7e3d4', // Light greyish green background
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CardMedia
              component="img"
              image={blogPost.image}
              alt={blogPost.title}
              sx={{
                maxWidth: '100%',
                maxHeight: 400,
                borderRadius: 2,
              }}
            />
            <Typography
              variant="h4"
              sx={{
                mt: 4,
                fontWeight: 'bold',
                fontFamily: 'Eagle Lake, cursive',
                color: '#5D4037', // Dark brown
              }}
            >
              {blogPost.title}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                mt: 1,
                fontStyle: 'italic',
                fontFamily: 'Playfair Display, serif',
                color: '#5D4037', // Dark brown
              }}
            >
              {blogPost.category} | {blogPost.date}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                fontFamily: 'Playfair Display, serif',
                color: '#5D4037', // Dark brown
              }}
            >
              By {blogPost.authors}
            </Typography>
            <Box
              sx={{
                mt: 4,
                lineHeight: 1.8,
                fontFamily: 'Playfair Display, serif',
                maxWidth: '800px',
                width: '100%',
                fontSize: '1.2rem',
                color: '#5D4037', // Dark brown
              }}
            >
              <ReactMarkdown>{blogPost.content}</ReactMarkdown>
            </Box>
          </Box>
        </Container>
      </main>

      {/* <main>
        <Container sx={{ py: 8 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CardMedia
              component="img"
              image={blogPost.image}
              alt={blogPost.title}
              sx={{ maxWidth: '100%', maxHeight: 400, borderRadius: 2 }}
            />
            <Typography
              variant="h4"
              sx={{
                mt: 4,
                fontWeight: 'bold',
                fontFamily: 'Eagle Lake, cursive',
              }}
            >
              {blogPost.title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="#e8f5e9"
              sx={{
                mt: 1,
                fontStyle: 'italic',
                fontFamily: 'Playfair Display, serif',
              }}
            >
              {blogPost.category} | {blogPost.date}
            </Typography>
            <Typography
              variant="body2"
              color="#e8f5e9"
              sx={{ mt: 1, fontFamily: 'Playfair Display, serif' }}
            >
              By {blogPost.authors}
            </Typography>
            <Box
              sx={{
                mt: 4,
                lineHeight: 1.8,
                fontFamily: 'Playfair Display, serif',
                maxWidth: '800px',
                width: '100%',
                color: '#2e7d32',
                fontSize: '1.2rem',
              }}
            >
              <ReactMarkdown>{blogPost.content}</ReactMarkdown>
            </Box>
          </Box>
        </Container>
      </main> */}
    </>
  );
};
