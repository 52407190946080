import React from 'react';
import Button from '@mui/material/Button';
import { keyframes } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { blue, red, green } from '@mui/material/colors';

const pulseGlow = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgb(0, 255, 65, 1);
  }
  50% {
    box-shadow: 0 0 20px 10px rgb(0, 255, 65, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(0, 255, 65, 0);
  }
`;

interface AnimatedButtonProps {
  buttonColor: string;
  text: string;
  delay: string;
}

const AnimatedButton = ({ buttonColor, text, delay }: AnimatedButtonProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      variant="outlined"
      sx={{
        color:
          buttonColor === 'red'
            ? red[500]
            : buttonColor === 'blue'
            ? blue[500]
            : green[500],
        borderColor:
          buttonColor === 'red'
            ? red[500]
            : buttonColor === 'blue'
            ? blue[500]
            : green[500],
        borderRadius: '20px',
        textTransform: 'uppercase',
        // start the glow animation immediately (no hover trigger)
        animation: `${pulseGlow} 2s ease-in-out infinite`,
        animationDelay: delay,
        transition: 'box-shadow 0.3s ease-in-out',
        overflow: 'visible',
        '&:hover': {
          textShadow: '0 0 5px #00FF41',
        },
        fontFamily: 'Space Mono, sans-serif',
        fontSize: isMobile ? '0.9rem' : '1rem',
        maxWidth: '200px',
      }}
      onClick={() => (text === 'home' ? navigate(`/`) : navigate(`/HomoDeus`))}
    >
      {text}
    </Button>
  );
};

export default AnimatedButton;
