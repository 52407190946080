/**
 *
 * Gig
 *
 */
import * as React from 'react';
import { BlogList } from './BlogList';
// import { BlogView } from './BlogView';

export function Gig() {
  // const style = { padding: '1rem' };

  return (
    <>
      {/* <div style={style}>This is Gig.</div> */}
      <BlogList />
    </>
  );
}
