import { QAItemType } from './QAItemType';

export const rejectOption: QAItemType[] = [
  {
    question: '',
    answer: '',
  },
  {
    question: 'If you choose to `Reject`,',
    answer: 'Then be warned about the Eternal Punishment of Unbelievers...',
  },
  {
    question:
      '❓:\\\u200A>  What happens to those who refuse to believe in Jesus?',
    answer:
      '📖 John 3:18 - He that believeth on him is not condemned: but he that believeth not is condemned already, because he hath not believed in the name of the only begotten Son of God.',
  },
  {
    question:
      '❓:\\\u200A>  What is the consequence of rejecting the Son of God?',
    answer:
      '📖 John 3:36 - He that believeth on the Son hath everlasting life: and he that believeth not the Son shall not see life; but the wrath of God abideth on him.',
  },
  {
    question:
      '❓:\\\u200A>  What kind of punishment awaits those who reject God?',
    answer:
      '📖 2 Thessalonians 1:8-9 - In flaming fire taking vengeance on them that know not God, and that obey not the gospel of our Lord Jesus Christ: Who shall be punished with everlasting destruction from the presence of the Lord, and from the glory of his power.',
  },
  {
    question: '❓:\\\u200A>  How severe is the suffering in hell?',
    answer:
      '📖 Mark 9:43-44 - And if thy hand offend thee, cut it off: it is better for thee to enter into life maimed, than having two hands to go into hell, into the fire that never shall be quenched: Where their worm dieth not, and the fire is not quenched.',
  },
  {
    question: '❓:\\\u200A>  Who will suffer the second death?',
    answer:
      '📖 Revelation 21:8 - But the fearful, and unbelieving, and the abominable, and murderers, and whoremongers, and sorcerers, and idolaters, and all liars, shall have their part in the lake which burneth with fire and brimstone: which is the second death.',
  },
  {
    question: 'The choice is yours.',
    answer: ' ',
  },
];
