import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import TypingTypography from 'pages/HomePage/TypingTypography';
import AnimatedButton from 'pages/HomePage/AnimatedButton';

export function NotFoundPage() {
  const textContainerRef = useRef<HTMLDivElement>(null);

  const [typingCompleted, setTypingCompleted] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typingCompleted && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [typingCompleted]);

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 4,
        backgroundColor: 'transparent',
        zIndex: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '90vh',
      }}
    >
      <Box ref={textContainerRef} sx={{ p: 3, textAlign: 'center' }}>
        <Typography
          variant="h6"
          sx={{ fontFamily: '"Space Mono", monospace', color: '#00FF41' }}
        >
          404 Page Not Found.
        </Typography>

        <TypingTypography
          text={`Looks like you're lost... but don't worry, you're in good company! The Son of Man came to seek and save the lost (Luke 19:10). While this page may not exist, Jesus does, and He's the best destination. Try heading back home!`}
          onComplete={() => setTypingCompleted(true)}
        />

        <Box sx={{ height: 50 }} />

        {typingCompleted && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <AnimatedButton buttonColor="green" text="home" delay="0s" />
          </Box>
        )}
      </Box>

      <Box ref={bottomRef} />
    </Container>
  );
}
