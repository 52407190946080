import { QAItemType } from './QAItemType';

export const acceptOption: QAItemType[] = [
  {
    question: '',
    answer: '',
  },
  {
    question: 'If you choose to `Accept`,',
    answer: 'Then you have the Promise of Eternal Life in Jesus Christ!',
  },
  {
    question: `${`❓:\\\u200A>  What is the greatest expression of God's love for humanity?`}`,
    answer:
      '📖 John 3:16 - For God so loved the world, that he gave his only begotten Son, that whosoever believeth in him should not perish, but have everlasting life.',
  },
  {
    question:
      '❓:\\\u200A>  Can anyone take away the eternal life that Jesus gives?',
    answer:
      "📖 John 10:28-29 - And I give unto them eternal life; and they shall never perish, neither shall any man pluck them out of my hand. My Father, which gave them me, is greater than all; and no man is able to pluck them out of my Father's hand.",
  },
  {
    question:
      '❓:\\\u200A>  How can we be certain that eternal life is found in Jesus?',
    answer:
      '📖 1 John 5:11-13 - And this is the record, that God hath given to us eternal life, and this life is in his Son. He that hath the Son hath life; and he that hath not the Son of God hath not life. These things have I written unto you that believe on the name of the Son of God; that ye may know that ye have eternal life, and that ye may believe on the name of the Son of God.',
  },
  {
    question: '❓:\\\u200A>  What happens when someone believes in Jesus?',
    answer:
      '📖 John 5:24 - Verily, verily, I say unto you, He that heareth my word, and believeth on him that sent me, hath everlasting life, and shall not come into condemnation; but is passed from death unto life.',
  },
  {
    question: '❓:\\\u200A>  How did Jesus make eternal life possible?',
    answer:
      '📖 2 Timothy 1:10 - But is now made manifest by the appearing of our Saviour Jesus Christ, who hath abolished death, and hath brought life and immortality to light through the gospel.',
  },
  {
    question: 'The choice is yours.',
    answer: ' ',
  },
];
