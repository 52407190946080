interface PathwayStep {
  text: string;
  verse: Verse[];
  options?: { label: string; next: string }[];
}

interface Verse {
  reference: string;
  passage: string;
}

export const pathway: Record<string, PathwayStep> = {
  start: {
    text: "Did you know? A renowned mathematician calculated the probability of Jesus fulfilling eight Old Testament prophecies and found as 1 in 10^17 (that's 17 zeros! 100,000,000,000,000,000) as unlikely as picking a marked silver dollar from a 2 ft deep pile covering Texas. The odds are so impossible! Yet, Jesus fulfilled over 300 prophecies, proving He is no ordinary man. He is indeed supernatural, the One True HOMO DEUS (Man God). Jesus declared, 'I am the way, the truth, and the life.' This means Jesus is the only way to Heaven: no other person, religion, ritual, or good deed can save you. The Bible is clear, Heaven or Hell awaits. Your choice determines your eternity. Will you trust in Jesus now, or regret it forever?",
    verse: [
      {
        reference: 'John 14:6',
        passage:
          'Jesus saith unto him, I am the way, the truth, and the life: no man cometh unto the Father, but by me.',
      },
      {
        reference: 'Acts 4:12',
        passage:
          'Neither is there salvation in any other: for there is none other name under heaven given among men, whereby we must be saved.',
      },
      {
        reference: 'Ephesians 2:8-9',
        passage:
          'For by grace are ye saved through faith; and that not of yourselves: it is the gift of God: Not of works, lest any man should boast.',
      },
      {
        reference: 'John 3:16',
        passage:
          'For God so loved the world, that he gave his only begotten Son, that whosoever believeth in him should not perish, but have everlasting life.',
      },
      {
        reference: 'Revelation 20:15',
        passage:
          'And whosoever was not found written in the book of life was cast into the lake of fire.',
      },
      {
        reference: 'Matthew 25:46',
        passage:
          'And these shall go away into everlasting punishment: but the righteous into life eternal',
      },
    ],
    options: [
      { label: "That's not right.", next: 'A2' }, //
      { label: 'Ok, tell me more.', next: 'A1' }, //
    ],
  },
  A1: {
    text: "Man sinned and fell short of God's glory, and the penalty of sin is Eternal Death in a place called Hell. But God, rich in mercy, sent Jesus to bear our sins on the Cross. God made Jesus to be sin for us, who knew no sin, so we might be made righteous in Him. Without shedding of His blood, there is no remission of sins. Not by our efforts, but by grace through faith, we can have access to Heaven.",
    verse: [
      {
        reference: 'Romans 3:23',
        passage: 'For all have sinned, and come short of the glory of God.',
      },
      {
        reference: 'Romans 6:23',
        passage:
          'For the wages of sin is death; but the gift of God is eternal life through Jesus Christ our Lord.',
      },
      {
        reference: 'Ephesians 2:4-5',
        passage:
          'But God, who is rich in mercy, for his great love wherewith he loved us, Even when we were dead in sins, hath quickened us together with Christ, (by grace ye are saved;)',
      },
      {
        reference: 'Isaiah 53:5',
        passage:
          'But he was wounded for our transgressions, he was bruised for our iniquities: the chastisement of our peace was upon him; and with his stripes we are healed.',
      },
      {
        reference: '2 Corinthians 5:21',
        passage:
          'For he hath made him to be sin for us, who knew no sin; that we might be made the righteousness of God in him.',
      },
      {
        reference: 'Hebrews 9:22',
        passage:
          'And almost all things are by the law purged with blood; and without shedding of blood is no remission.',
      },
      {
        reference: 'Ephesians 2:8-9',
        passage:
          'For by grace are ye saved through faith; and that not of yourselves: it is the gift of God: Not of works, lest any man should boast.',
      },
    ],
    options: [
      { label: "I don't need saving.", next: 'B2' }, //
      { label: 'How can I be saved?', next: 'B1' }, //
    ],
  },
  A2: {
    text: "God is righteous, are you? According to God's standard, no human is truly righteous, for there is none righteous, no, not one. Heaven is a perfect place that belongs to God, and only those who meet His standard of righteousness can enter. Some believe they can earn Heaven by being good, but the Holy Bible says all have sinned, and come short of the glory of God, and no amount of good works can erase our guilt. Without the shedding of blood, there is no forgiveness of sins. But Jesus, God in the flesh, declared, 'This is my blood of the new testament, which is shed for many for the remission of sins'. Only Jesus can save!",
    verse: [
      {
        reference: 'Psalm 11:7',
        passage:
          'For the righteous LORD loveth righteousness; his countenance doth behold the upright.',
      },
      {
        reference: 'Romans 3:10',
        passage: 'As it is written, There is none righteous, no, not one.',
      },
      {
        reference: '1 Corinthians 6:9-10',
        passage:
          'Know ye not that the unrighteous shall not inherit the kingdom of God? Be not deceived: neither fornicators, nor idolaters, nor adulterers, nor effeminate, nor abusers of themselves with mankind, Nor thieves, nor covetous, nor drunkards, nor revilers, nor extortioners, shall inherit the kingdom of God.',
      },
      {
        reference: 'Romans 3:23',
        passage: 'For all have sinned, and come short of the glory of God.',
      },
      {
        reference: 'Isaiah 64:6',
        passage:
          'But we are all as an unclean thing, and all our righteousnesses are as filthy rags; and we all do fade as a leaf; and our iniquities, like the wind, have taken us away.',
      },
      {
        reference: 'Ephesians 2:8-9',
        passage:
          'For by grace are ye saved through faith; and that not of yourselves: it is the gift of God: Not of works, lest any man should boast.',
      },
      {
        reference: 'Hebrews 9:22',
        passage:
          'And almost all things are by the law purged with blood; and without shedding of blood is no remission.',
      },
      {
        reference: 'Matthew 26:28',
        passage:
          'For this is my blood of the new testament, which is shed for many for the remission of sins.',
      },
    ],
    options: [
      { label: 'Good works can save!', next: 'B3' }, //
      { label: 'How do I get saved?', next: 'B1' }, //
    ],
  },
  B1: {
    text: 'Repent! Search the scriptures; for in them ye think ye have eternal life, and they are they which testify of Jesus Christ. Faith comes by hearing, and hearing by the word of God, for without faith, it is impossible to please Him. To believe in Him, you must confess with your mouth the Lord Jesus and believe in your heart that God raised Him from the dead, and you shall be saved.',
    verse: [
      {
        reference: 'Luke 13:3',
        passage:
          'I tell you, Nay: but, except ye repent, ye shall all likewise perish.',
      },
      {
        reference: 'John 5:39',
        passage:
          'Search the scriptures; for in them ye think ye have eternal life: and they are they which testify of me.',
      },
      {
        reference: 'Romans 10:17',
        passage:
          'So then faith cometh by hearing, and hearing by the word of God.',
      },
      {
        reference: 'Hebrews 11:6',
        passage:
          'But without faith it is impossible to please him: for he that cometh to God must believe that he is, and that he is a rewarder of them that diligently seek him.',
      },
      {
        reference: 'Romans 10:9',
        passage:
          'That if thou shalt confess with thy mouth the Lord Jesus, and shalt believe in thine heart that God hath raised him from the dead, thou shalt be saved.',
      },
    ],
    options: [
      { label: "I won't do that.", next: 'C2' }, //
      { label: 'I want to do that.', next: 'C1' }, //
    ],
  },
  B2: {
    text: "Many people believe they are fine without Jesus. Perhaps you trust in your wealth, achievements, or even your own goodness. When you die, none of these things will matter. Your wealth will be left behind, your achievements will fade, and your goodness will fall short of God's perfect standard. Without Him, we are already lost, for he that believeth not is condemned already. No amount success, status, or effort can afford your ticket to eternal life. Only Jesus can take you there. Are you sure you want to reject this offer of salvation?",
    verse: [
      {
        reference: 'John 3:18',
        passage:
          'He that believeth on him is not condemned: but he that believeth not is condemned already, because he hath not believed in the name of the only begotten Son of God.',
      },
      {
        reference: 'Luke 19:10',
        passage:
          'For the Son of man is come to seek and to save that which was lost.',
      },
      {
        reference: 'John 14:6',
        passage:
          'Jesus saith unto him, I am the way, the truth, and the life: no man cometh unto the Father, but by me.',
      },
      {
        reference: 'Acts 4:12',
        passage:
          'Neither is there salvation in any other: for there is none other name under heaven given among men, whereby we must be saved.',
      },
      {
        reference: 'John 10:10',
        passage:
          'The thief cometh not, but for to steal, and to kill, and to destroy: I am come that they might have life, and that they might have it more abundantly.',
      },
    ],
    options: [
      { label: "I don't believe that.", next: 'C3' }, //
      { label: "I don't want to be lost.", next: 'B1' }, //
    ],
  },
  B3: {
    text: "What you believe only matters if you own Heaven. Unless you own Heaven, you don't set the rules. God does. His standard is perfection, and none of us measure up. That's why we need Jesus and stop making the rules or following the wrong ones. No matter how hard we try, we can never be perfect, because there is none righteous. In God's eyes, all our righteousnesses are as filthy rags. But Jesus is perfect, He is God in the flesh who came to save us. We must trust Him, not ourselves.",
    verse: [
      {
        reference: 'John 17:17',
        passage: 'Sanctify them through thy truth: thy word is truth.',
      },
      {
        reference: 'Proverbs 14:12',
        passage:
          'There is a way which seemeth right unto a man, but the end thereof are the ways of death.',
      },
      {
        reference: 'Romans 3:10',
        passage: 'As it is written, There is none righteous, no, not one.',
      },
      {
        reference: 'Isaiah 64:6',
        passage:
          'But we are all as an unclean thing, and all our righteousnesses are as filthy rags; and we all do fade as a leaf; and our iniquities, like the wind, have taken us away.',
      },
      {
        reference: 'Hebrews 4:15',
        passage:
          'For we have not an high priest which cannot be touched with the feeling of our infirmities; but was in all points tempted like as we are, yet without sin.',
      },
      {
        reference: 'John 1:14',
        passage:
          'And the Word was made flesh, and dwelt among us, (and we beheld his glory, the glory as of the only begotten of the Father,) full of grace and truth.',
      },
      {
        reference: 'Ephesians 2:8-9',
        passage:
          'For by grace are ye saved through faith; and that not of yourselves: it is the gift of God: Not of works, lest any man should boast.',
      },
    ],
    options: [
      { label: 'Good works are enough!', next: 'C3' }, //
      { label: 'What should I do?', next: 'B1' }, //
    ],
  },
  C1: {
    text: "Jesus is ready to save you! The Bible says, 'Believe on the Lord Jesus Christ, and thou shalt be saved'. If you trust in Him, you can express your faith in prayer, asking Him to save you.",
    verse: [
      {
        reference: 'Romans 10:13',
        passage:
          'For whosoever shall call upon the name of the Lord shall be saved.',
      },
      {
        reference: 'Acts 2:21',
        passage:
          'And it shall come to pass, that whosoever shall call on the name of the Lord shall be saved.',
      },
      {
        reference: 'Psalm 145:18',
        passage:
          'The Lord is nigh unto all them that call upon him, to all that call upon him in truth.',
      },
      {
        reference: 'Isaiah 55:6',
        passage:
          'Seek ye the Lord while he may be found, call ye upon him while he is near.',
      },
      {
        reference: 'John 6:37',
        passage:
          'All that the Father giveth me shall come to me; and him that cometh to me I will in no wise cast out.',
      },
      {
        reference: 'Ephesians 2:8-9',
        passage:
          'For by grace are ye saved through faith; and that not of yourselves: it is the gift of God: Not of works, lest any man should boast.',
      },
    ],
    options: [
      { label: "I'm not sure yet.", next: 'C3' }, //
      { label: "What's next?", next: 'C4' }, //
    ],
  },
  C2: {
    text: "Truth is not based on feelings but on reality. If Jesus is who He says He is, the only way to God, then rejecting Him has eternal consequences. Doubt is natural, but truth doesn't change! God promises, 'Ye shall seek me, and find me, when ye shall search for me with all your heart'. If you genuinely seek Him, He will show you the truth.",
    verse: [
      {
        reference: 'John 14:6',
        passage:
          'Jesus saith unto him, I am the way, the truth, and the life: no man cometh unto the Father, but by me.',
      },
      {
        reference: 'Proverbs 14:12',
        passage:
          'There is a way which seemeth right unto a man, but the end thereof are the ways of death.',
      },
      {
        reference: '2 Corinthians 4:3-4',
        passage:
          'But if our gospel be hid, it is hid to them that are lost: In whom the god of this world hath blinded the minds of them which believe not, lest the light of the glorious gospel of Christ, who is the image of God, should shine unto them.',
      },
      {
        reference: 'Hebrews 9:27',
        passage:
          'And as it is appointed unto men once to die, but after this the judgment.',
      },
      {
        reference: 'Jeremiah 29:13',
        passage:
          'And ye shall seek me, and find me, when ye shall search for me with all your heart.',
      },
      {
        reference: 'Isaiah 55:6',
        passage:
          'Seek ye the Lord while he may be found, call ye upon him while he is near.',
      },
    ],
    options: [
      { label: "That's not fair!", next: 'C3' }, //
      { label: 'I want to receive Jesus.', next: 'C1' }, //
    ],
  },
  C3: {
    text: 'God is not being unfair. Ask yourself, are you without sin? He is showing you mercy, offering to erase your sins. If you demand fairness, remember that true justice would mean facing the full penalty for your sins. Instead, He offers you salvation as a free gift through faith in Jesus Christ. Rejecting Him means rejecting life, and the Bible warns that Hell is real for those who refuse to believe in the only Savior, Jesus Christ.',
    verse: [
      {
        reference: 'Psalm 103:10',
        passage:
          'He hath not dealt with us after our sins; nor rewarded us according to our iniquities.',
      },
      {
        reference: 'Lamentations 3:22-23',
        passage:
          "It is of the Lord's mercies that we are not consumed, because his compassions fail not. They are new every morning: great is thy faithfulness.",
      },
      {
        reference: 'Ephesians 2:8-9',
        passage:
          'For by grace are ye saved through faith; and that not of yourselves: it is the gift of God: Not of works, lest any man should boast.',
      },
      {
        reference: 'Romans 6:23',
        passage:
          'For the wages of sin is death; but the gift of God is eternal life through Jesus Christ our Lord.',
      },
      {
        reference: 'John 3:18',
        passage:
          'He that believeth on him is not condemned: but he that believeth not is condemned already, because he hath not believed in the name of the only begotten Son of God.',
      },
      {
        reference: 'Revelation 20:15',
        passage:
          'And whosoever was not found written in the book of life was cast into the lake of fire.',
      },
    ],
    options: [
      { label: "I'm not convinced.", next: 'end' }, //
      { label: "I'll think about it.", next: 'end' }, //
    ],
  },
  C4: {
    text: "As a child of God, you are now an heir of salvation and eternal life in Heaven. Your journey of faith has just begun. Grow by reading God's Word, praying daily, and gathering with other believers. Share the good news of Jesus with others. You are now a witness for Christ, so let your life reflect His truth and lead others to Him!",
    verse: [
      {
        reference: 'Romans 8:17',
        passage:
          'And if children, then heirs; heirs of God, and joint-heirs with Christ; if so be that we suffer with him, that we may be also glorified together.',
      },
      {
        reference: 'Philippians 3:20',
        passage:
          'For our conversation is in heaven; from whence also we look for the Saviour, the Lord Jesus Christ.',
      },
      {
        reference: '1 Peter 2:2',
        passage:
          'As newborn babes, desire the sincere milk of the word, that ye may grow thereby.',
      },
      { reference: '1 Thessalonians 5:17', passage: 'Pray without ceasing.' },
      {
        reference: 'Hebrews 10:25',
        passage:
          'Not forsaking the assembling of ourselves together, as the manner of some is; but exhorting one another: and so much the more, as ye see the day approaching.',
      },
      {
        reference: 'Matthew 5:14',
        passage:
          'Ye are the light of the world. A city that is set on an hill cannot be hid.',
      },
      {
        reference: 'Mark 16:15',
        passage:
          'And he said unto them, Go ye into all the world, and preach the gospel to every creature.',
      },
    ],
    options: [
      { label: 'I still have questions.', next: 'C5' }, //
      { label: 'I will do that!', next: 'end' }, //
    ],
  },
  C5: {
    text: "If you still have questions, ask God for wisdom. The Bible says, 'If any of you lack wisdom, let him ask of God, that giveth to all men liberally, and upbraideth not; and it shall be given him.'",
    verse: [
      {
        reference: 'James 1:5',
        passage:
          'If any of you lack wisdom, let him ask of God, that giveth to all men liberally, and upbraideth not; and it shall be given him.',
      },
      {
        reference: 'Proverbs 2:6',
        passage:
          'For the Lord giveth wisdom: out of his mouth cometh knowledge and understanding.',
      },
      {
        reference: 'Proverbs 3:5-6',
        passage:
          'Trust in the Lord with all thine heart; and lean not unto thine own understanding. In all thy ways acknowledge him, and he shall direct thy paths.',
      },
      {
        reference: 'Jeremiah 33:3',
        passage:
          'Call unto me, and I will answer thee, and shew thee great and mighty things, which thou knowest not.',
      },
      {
        reference: 'Matthew 7:7',
        passage:
          'Ask, and it shall be given you; seek, and ye shall find; knock, and it shall be opened unto you.',
      },
    ],
    options: [
      { label: 'I still have doubts.', next: 'C2' }, //
      { label: 'I will pray for wisdom.', next: 'end' }, //
    ],
  },
  end: {
    text: "Think about it: Are there any religious founders who rose from the dead, proving their power over sin and death? None, except Jesus Christ. No other leader, prophet, or teacher can offer eternal life, but Jesus declared, 'I am the resurrection, and the life: he that believeth in me, though he were dead, yet shall he live'. Only through Him can we be saved, for neither is there salvation in any other: for there is none other name under heaven given among men, whereby we must be saved.  Rebellion against God led to the creation of Hell, a place originally prepared for the devil and his angels, yet all who reject Christ will share in their fate. One day, whether now or in eternity, you will bow before your Creator. The question is not if you will believe in Jesus, but when. If you were to die today, are you absolutely sure you would go to Heaven? There are no second chances after death!",
    verse: [
      {
        reference: 'John 11:25',
        passage:
          'Jesus said unto her, I am the resurrection, and the life: he that believeth in me, though he were dead, yet shall he live.',
      },
      {
        reference: 'Acts 4:12',
        passage:
          'Neither is there salvation in any other: for there is none other name under heaven given among men, whereby we must be saved.',
      },
      {
        reference: 'Philippians 2:10-11',
        passage:
          'That at the name of Jesus every knee should bow, of things in heaven, and things in earth, and things under the earth; And that every tongue should confess that Jesus Christ is Lord, to the glory of God the Father.',
      },
      {
        reference: 'Romans 14:11',
        passage:
          'For it is written, As I live, saith the Lord, every knee shall bow to me, and every tongue shall confess to God.',
      },
      {
        reference: 'Hebrews 9:27',
        passage:
          'And as it is appointed unto men once to die, but after this the judgment.',
      },
      {
        reference: '2 Corinthians 6:2',
        passage:
          'For he saith, I have heard thee in a time accepted, and in the day of salvation have I succoured thee: behold, now is the accepted time; behold, now is the day of salvation.',
      },
    ],
  },
};
