import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import TypingTypography from './TypingTypography';
import { pathway } from './GospelPathwayData';
import { blue, red } from '@mui/material/colors';
import { keyframes } from '@mui/system';
import { MatrixRainingLetters } from './MatrixRainingLetters';
import AnimatedButton from './AnimatedButton';

const GospelPathway = () => {
  const pulseGlow = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgb(0, 255, 65, 1);
  }
  50% {
    box-shadow: 0 0 20px 10px rgb(0, 255, 65, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgb(0, 255, 65, 0);
  }
`;

  const [currentStep, setCurrentStep] = useState('start');
  const [typingCompleted, setTypingCompleted] = useState(false);

  const step = pathway[currentStep] || { text: '', verse: [], options: [] };

  const handleNextStep = (nextStep: string) => {
    setTypingCompleted(false);
    setCurrentStep(nextStep);
  };

  const textContainerRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    if (textContainerRef.current) {
      textContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [currentText]);

  useEffect(() => {
    if (typingCompleted) {
      const scrollToTarget = bottomRef.current || textContainerRef.current;
      if (scrollToTarget) {
        scrollToTarget.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [typingCompleted]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <MatrixRainingLetters />
      <Container
        maxWidth="md"
        sx={{
          mt: 4,
          backgroundColor: 'transparent',
          zIndex: 10,
        }}
      >
        <Box
          ref={textContainerRef}
          sx={{
            p: 3,
            backgroundColor: 'transparent',
          }}
        >
          <TypingTypography
            text={step.text.trim()}
            onComplete={() => setTypingCompleted(true)}
            onTextUpdate={setCurrentText}
          />

          {typingCompleted && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                  mt: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {step.verse &&
                  step.verse.map((v, index) => (
                    <React.Fragment key={index}>
                      <Tooltip
                        title={`"${v.passage}"`}
                        placement="bottom"
                        followCursor
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: 'italic',
                            fontFamily: '"Space Mono", monospace',
                            color: '#00FF41',
                          }}
                        >
                          {v.reference}
                        </Typography>
                      </Tooltip>
                      {index < step.verse.length - 1 && (
                        <span style={{ color: '#00FF41' }}>|</span>
                      )}{' '}
                    </React.Fragment>
                  ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                  mt: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {step.options ? (
                  <Box sx={{ mt: 3 }}>
                    {step.options.map((option, index) => (
                      <Button
                        key={option.label}
                        variant="outlined"
                        sx={{
                          m: 1,
                          borderRadius: '20px',
                          borderColor: index === 0 ? blue[500] : red[500],
                          color: index === 0 ? blue[500] : red[500],
                          animation: `${pulseGlow} 2s ease-in-out infinite`,
                          animationDelay: `${(index + 1) * 0.5}s`,
                          transition: 'box-shadow 0.3s ease-in-out',
                          overflow: 'visible',
                          '&:hover': {
                            textShadow: '0 0 5px #00FF41',
                          },
                          fontFamily: 'Space Mono, sans-serif',
                          fontSize: isMobile ? '0.8rem' : '1rem',
                        }}
                        onClick={() => handleNextStep(option.next)}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </Box>
                ) : (
                  <AnimatedButton
                    buttonColor={'green'}
                    text={'home'}
                    delay={'0s'}
                  />
                )}
              </Box>
              <Box ref={bottomRef}></Box>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default GospelPathway;
