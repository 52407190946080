import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Link,
} from '@mui/material';
import { MatrixRainingLetters } from './MatrixRainingLetters';
import AnimatedButton from './AnimatedButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export function HomePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentYear = new Date().getFullYear();

  const peaceTranslations = [
    { lang: 'English', text: 'Peace!' },
    { lang: 'Hawaiian', text: 'Aloha!' },
    { lang: 'French', text: 'Paix!' },
    { lang: 'Spanish', text: 'Paz!' },
    { lang: 'German', text: 'Frieden!' },
    { lang: 'English', text: 'Peace!' },
    { lang: 'Japanese', text: '平和!' },
    { lang: 'Korean', text: '평화!' },
    { lang: 'Chinese', text: '和平!' },
    { lang: 'Ark', text: 'Arkware.app' },
    { lang: 'English', text: 'Peace!' },
    { lang: 'Hawaiian', text: 'Aloha!' },
    { lang: 'Hebrew', text: 'שלום!' },
    { lang: 'Arabic', text: 'سلام!' },
    { lang: 'Russian', text: 'Мир!' },
    { lang: 'English', text: 'Peace!' },
    { lang: 'Greek', text: 'Ειρήνη!' },
    { lang: 'Swahili', text: 'Amani!' },
    { lang: 'Hindi', text: 'शांति!' },
    { lang: 'Ark', text: 'Arkware.app' },
  ];

  const [index, setIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const text = peaceTranslations[index]?.text ?? ''; // ensure a valid string

  useEffect(() => {
    let i = 0;
    setDisplayedText(''); // reset before starting typing

    const typingInterval = setInterval(() => {
      if (i <= text.length) {
        // ensure full word is typed out
        setDisplayedText(text.slice(0, i)); // use `slice(0, i)` instead of `prev + text[i]`
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 120); // typing speed per character

    return () => clearInterval(typingInterval); // cleanup interval on re-render
  }, [text]); // depend on text, so updates properly

  useEffect(() => {
    const delayBeforeNext = text.length * 100 + 1000; // wait for typing + pause before switching
    const switchWordTimeout = setTimeout(() => {
      setIndex((prevIndex) => (prevIndex + 1) % peaceTranslations.length);
    }, delayBeforeNext);

    return () => clearTimeout(switchWordTimeout); // cleanup timeout on re-render
  }, [text]); // depend on text to wait for correct typing delay
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100vw',
        minHeight: '100vh', // fit exactly to screen height
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#00FF41',
        fontFamily: '"Space Mono", monospace',
        textAlign: 'center',
        overflow: 'hidden', // prevent scrolling
      }}
    >
      {/* Background Matrix Effect */}
      <MatrixRainingLetters />

      <Box
        sx={{
          position: 'relative',
          zIndex: 1, // ensure text is above the background
          width: '100%',
          maxWidth: '90%',
          flexGrow: 1, // allow content to expand within available space
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {/* Main Content */}
        <Typography
          variant={isMobile ? 'h5' : 'h2'}
          sx={{
            fontWeight: 'bold',
            textShadow: '0 0 10px #00FF41',
            fontFamily: 'Space Mono, sans-serif',
            wordBreak: 'break-word',
            letterSpacing: '0.01em', // disable ligatures
          }}
        >
          <span style={{ fontVariantLigatures: 'none' }}>
            C:\&#8202;&gt;{displayedText}
            <span className="blinking-cursor">▊</span>
          </span>
        </Typography>
        <style>
          {`
          .blinking-cursor {
            animation: blink 1s step-end infinite;
            fontVariantLigatures: 'none';
          }
          @keyframes blink { 
            50% { opacity: 0; } 
          }
        `}
        </style>
        <Typography
          variant={isMobile ? 'caption' : 'body1'}
          sx={{
            mt: 2,
            textShadow: '0 0 5px #00FF41',
            fontFamily: 'Space Mono, sans-serif',
          }}
        >
          In the beginning was the Word, and the Word was with God, and the Word
          was God... And the Word was made flesh, and dwelt among us... full of
          grace and truth:
        </Typography>
        <Typography
          variant={isMobile ? 'body2' : 'h6'}
          sx={{
            mt: 2,
            textShadow: '0 0 5px #00FF41',
            fontFamily: 'Space Mono, sans-serif',
          }}
        >
          יֵשׁוּעַ הַמָּשִׁיחַ . 耶稣基督. イエス・キリスト. 예수 그리스도. يسوع
          المسيح . Ἰησοῦς Χριστός. Iēsus Chrīstus. Jesucristo.
        </Typography>
        <Typography
          variant={isMobile ? 'body1' : 'h5'}
          sx={{
            mt: 2,
            textShadow: '0 0 5px #00FF41',
            fontFamily: 'Space Mono, sans-serif',
          }}
        >
          Jesus Christ.
        </Typography>
        <Typography
          variant={isMobile ? 'body1' : 'h6'}
          sx={{
            mt: 2,
            textShadow: '0 0 5px #00FF41',
            fontFamily: 'Space Mono, sans-serif',
          }}
        >
          Follow the One True Homo Deus:
        </Typography>
        <Box sx={{ mt: 2, width: '100%' }}>
          <Grid
            container
            spacing={isMobile ? 2 : 4}
            direction="row"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid item xs={6} sm="auto">
              <AnimatedButton buttonColor={'blue'} text={'reject'} delay="0s" />
            </Grid>
            <Grid item xs={6} sm="auto">
              <AnimatedButton
                buttonColor={'red'}
                text={'accept'}
                delay="0.5s"
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography
            //   variant={'caption'}
            variant={isMobile ? 'caption' : 'body1'}
            sx={{
              mt: 2,
              textShadow: '0 0 5px #00FF41',
              fontFamily: 'Space Mono, sans-serif',
              fontStyle: 'italic',
            }}
          >
            &quot;blue pill... red pill...
            <br />
            Remember... all I&apos;m offering is the truth.&quot;
          </Typography>
        </Box>
        <Typography
          variant={isMobile ? 'body2' : 'h6'}
          sx={{
            mt: isMobile ? 3 : 15,
            fontFamily: 'Space Mono, sans-serif',
          }}
        >
          ©️ {currentYear}{' '}
          <Link
            href="https://www.linkedin.com/in/arb-lim-msc-swe/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Arb Lim <LinkedInIcon fontSize="small" sx={{ ml: 0.5 }} />
          </Link>
          , Arkware.app™
        </Typography>
      </Box>
    </Box>
  );
}
