import React, { useState, useEffect } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

interface TypingTypographyProps {
  text: string;
  onComplete?: () => void;
  onTextUpdate?: (text: string) => void;
  speed?: number;
}

const TypingTypography = ({
  text = '',
  onComplete,
  onTextUpdate,
  speed = 60,
}: TypingTypographyProps) => {
  const [displayedText, setDisplayedText] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setDisplayedText('');
    setIndex(0);

    if (!text.trim()) return;

    let currentIndex = 0;
    const interval = setInterval(() => {
      const newText = text.slice(0, currentIndex + 1);
      setDisplayedText(newText);
      if (onTextUpdate) onTextUpdate(newText);
      currentIndex++;

      if (currentIndex >= text.length) {
        clearInterval(interval);
        if (onComplete) onComplete();
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, onTextUpdate]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Typography
        variant={isMobile ? 'body2' : 'h6'}
        component="pre"
        sx={{
          flexGrow: 1,
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          fontFamily: '"Space Mono", monospace',
          color: '#00FF41',
          '&::-webkit-scrollbar-thumb': {
            background: '#00FF41',
            borderRadius: '3px',
          },
          width: '100%',
          letterSpacing: '0.01em',
        }}
      >
        {displayedText}
        <span className="blinking-cursor">
          ▊
          <br />
        </span>
      </Typography>
      <style>
        {`
            .blinking-cursor {
                animation: blink 1s step-end infinite;
                fontVariantLigatures: 'none';
                }
                @keyframes blink { 
              50% { opacity: 0; } 
              }
              `}
      </style>
    </>
  );
};

export default TypingTypography;
