/**
 *
 * Blog
 *
 */

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
  CssBaseline,
  Chip,
  InputBase,
  IconButton,
  TextField,
  Link,
  Pagination,
  Avatar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { CopyrightRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import '@fontsource/eagle-lake';
import '@fontsource/playfair-display';
import Arkware from '../../../assets/Arkware.png';

const generateBlogPosts = () => {
  const categories = ['Salvation', 'Trinity', 'Rapture', 'Sanctification'];
  const images = [
    'https://picsum.photos/id/1/800/600',
    'https://picsum.photos/id/2/800/600',
    'https://picsum.photos/id/3/800/600',
    'https://picsum.photos/id/4/800/600',
    'https://picsum.photos/id/5/800/600',
  ];
  return Array.from({ length: 20 }, (_, index) => ({
    title: `Blog Post ${index + 1}`,
    description: `This is the description for blog post ${
      index + 1
    }. It provides a brief overview of the blog's content and theme.`,
    image: images[index % images.length],
    category: categories[index % categories.length],
    date: `July ${index + 1}, 2023`,
    authors: `Author ${index + 1}`,
  }));
};

const categories = [
  'All categories',
  'Salvation',
  'Trinity',
  'Rapture',
  'Sanctification',
];

export const BlogList = () => {
  const blogPosts = generateBlogPosts();
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('All categories');
  const postsPerPage = 4;

  const filteredPosts =
    selectedCategory === 'All categories'
      ? blogPosts
      : blogPosts.filter((post) => post.category === selectedCategory);

  const paginatedPosts = filteredPosts.slice(
    (page - 1) * postsPerPage,
    page * postsPerPage,
  );

  const getInitials = (name) => {
    const words = name.split(' ');
    return words.length > 1
      ? words[0][0].toUpperCase() + words[1][0].toUpperCase()
      : words[0][0].toUpperCase();
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
    setPage(1); // Reset to first page when category changes
  };

  const navigate = useNavigate();

  return (
    <>
      <CssBaseline />
      <AppBar position="relative" sx={{ backgroundColor: '#2e7d32' }}>
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            sx={{ fontFamily: 'Eagle Lake, cursive' }}
          >
            Gig
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: 'flex', alignItems: 'center', maxWidth: '250px' }}
          >
            <InputBase
              placeholder="Search…"
              sx={{
                ml: 1,
                flex: 1,
                backgroundColor: '#a5d6a7',
                padding: '0 8px',
                borderRadius: 1,
              }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <IconButton color="inherit">
              <RssFeedIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <main>
        <Box
          sx={{
            backgroundColor: '#e8f5e9',
            px: 4,
            textAlign: 'center',
            position: 'relative',
            zIndex: 1,
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-40px',
              left: 0,
              right: 0,
              height: '300px',
              backgroundColor: '#e8f5e9',
              clipPath: 'ellipse(100% 300px at 50% 0%)',
              zIndex: -1,
            },
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              // gutterBottom
              padding={4}
              sx={{ fontFamily: 'Eagle Lake, cursive' }}
            >
              Grow in grace
            </Typography>
            <Typography
              variant="h5"
              color="text.secondary"
              sx={{
                fontFamily: 'Playfair Display, serif',
                fontStyle: 'italic',
              }}
            >
              Notes and Insights on <br />
              Salvation & Other Bible Doctrines
            </Typography>
            <Box
              sx={{
                p: 3,
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
                gap: 1,
                flexWrap: 'wrap',
              }}
            >
              {categories.map((category, index) => (
                <Chip
                  label={category}
                  key={index}
                  clickable
                  onClick={() => handleCategoryChange(category)}
                  sx={{
                    backgroundColor:
                      selectedCategory === category ? '#81c784' : undefined,
                    fontFamily: 'Playfair Display, serif',
                  }}
                />
              ))}
            </Box>
          </Container>
        </Box>
        <Container sx={{ pt: 10, pb: 12 }} maxWidth="md">
          <Grid container spacing={4}>
            {paginatedPosts.map((post, index) => (
              <Grid item key={index} xs={12} sm={6} md={6}>
                <Card
                  onClick={() => navigate(`/gig/blog/${index + 1}`)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: '#e8f5e9',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={post.image}
                    alt={post.title}
                    sx={{ height: 200 }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" color="text.secondary">
                      {post.category}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{ fontFamily: 'Eagle Lake, cursive' }}
                    >
                      {post.title}
                    </Typography>
                    <Typography sx={{ fontFamily: 'Playfair Display, serif' }}>
                      {post.description}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2,
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: '#2e7d32',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography sx={{ fontFamily: 'Eagle Lake, cursive' }}>
                          {getInitials(post.authors)}
                        </Typography>
                      </Avatar>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontFamily: 'Playfair Display, serif' }}
                      >
                        {post.authors}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontFamily: 'Playfair Display, serif' }}
                      >
                        {post.date}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
              pt: 4,
              zIndex: 999,
            }}
          >
            <Pagination
              count={Math.ceil(filteredPosts.length / postsPerPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: (theme) => theme.palette.text.primary,
                  backgroundColor: (theme) => theme.palette.background.default,
                },
                '& .Mui-selected': {
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.primary.contrastText,
                },
                zIndex: 999,
                mt: 6,
              }}
            />
          </Box>
        </Container>
      </main>

      <ArkGigFooter />
    </>
  );
};

const ArkGigFooter = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <footer>
      <Box
        sx={{
          backgroundColor: '#2e7d32',
          color: (theme) => theme.palette.primary.contrastText,
          textAlign: 'center',
          position: 'relative',
          zIndex: 1,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '-175px',
            left: 0,
            right: 0,
            height: '200px',
            backgroundColor: '#2e7d32',
            clipPath: 'ellipse(100% 200px at 50% 100%)',
            zIndex: -1,
          },
          pb: 2,
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            spacing={4}
          >
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h6"
                gutterBottom
                color="inherit"
                sx={{ fontFamily: 'Playfair Display, serif' }}
              >
                Join the newsletter
              </Typography>
              <Typography
                variant="body2"
                color="inherit"
                sx={{ fontFamily: 'Playfair Display, serif' }}
              >
                Subscribe for weekly updates. No spams ever!
              </Typography>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}
              >
                <TextField
                  placeholder="Your email address"
                  variant="outlined"
                  size="small"
                  sx={{
                    flex: 1,
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#ffffff',
                      '& fieldset': {
                        borderColor: '#ffffff',
                      },
                      '&:hover fieldset': {
                        borderColor: '#ffffff',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#ffffff',
                      },
                    },
                    '& input': {
                      color: '#000000',
                    },
                    fontFamily: 'Playfair Display, serif',
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#ffffff',
                    color: '#2e7d32',
                    '&:hover': {
                      backgroundColor: '#c8e6c9',
                    },
                    fontFamily: 'Playfair Display, serif',
                  }}
                >
                  Subscribe
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography
                variant="h6"
                gutterBottom
                color="inherit"
                sx={{ fontFamily: 'Playfair Display, serif' }}
              >
                Legal
              </Typography>
              {['Privacy Policy', 'Terms of Service', 'Contact'].map(
                (item, idx) => (
                  <Link
                    key={idx}
                    href="#"
                    variant="body2"
                    color="inherit"
                    sx={{
                      display: 'block',
                      mt: 0.5,
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                      fontFamily: 'Playfair Display, serif',
                    }}
                  >
                    {item}
                  </Link>
                ),
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'center', sm: 'space-between' },
              alignItems: { xs: 'center', sm: 'center' },
              mt: 4,
              pt: 2,
              borderTop: '1px solid rgba(255, 255, 255, 0.3)',
              gap: { xs: 2, sm: 0 }, // Add spacing between rows on narrow screens
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                alignItems: 'center',
                maxWidth: '100%',
                flexShrink: 1,
              }}
            >
              <CopyrightRounded sx={{ color: 'rgba(0, 0, 0, 0.18)' }} />{' '}
              <Chip
                sx={{ fontFamily: 'Space Mono, sans-serif', color: '#fff' }}
                label={`${currentYear} Arb Lim | Arkware.app`}
              />
            </Box>

            <Box sx={{ display: 'flex', gap: 1 }}>
              {[
                { type: 'image', src: Arkware, alt: 'Arkware', url: '/' },
                // { type: 'icon', component: X },
                { type: 'icon', component: LinkedInIcon },
              ].map((item, idx) => {
                if (item.type === 'image') {
                  return (
                    <IconButton
                      key={idx}
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        console.log(`IconButton ${idx} clicked`);
                        navigate('/');
                      }}
                      sx={{
                        color: '#ffffff',
                        '&:hover': {
                          color: '#c8e6c9',
                        },
                      }}
                    >
                      <img
                        src={item.src}
                        alt={item.alt}
                        style={{ width: 30, height: 30 }}
                      />
                    </IconButton>
                  );
                }

                if (item.type === 'icon' && item.component) {
                  const IconComponent = item.component;
                  return (
                    <IconButton
                      key={idx}
                      href="#"
                      sx={{
                        color: '#ffffff',
                        '&:hover': {
                          color: '#c8e6c9',
                        },
                      }}
                    >
                      <IconComponent />
                    </IconButton>
                  );
                }

                return null;
              })}
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};
